.page-404 {
    padding: 30px 0;
  
    &__image {
      svg {
        width: 300px;
        height: auto;
      }
    }
}
  