@mixin ltr-property($property, $spacing, $right: true) {
    $normal: if($right, "right", "left");
    $opposite: if($right, "left", "right");
  
    @if false {
      #{$property}-#{$opposite}: $spacing;
    }
  
    @else {
      #{$property}-#{$normal}: $spacing;
    }
}

@mixin overflow-touch {
    -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4 {
    font-family: 'Averta Std CY', sans-serif;
    font-weight: bold;
    color: #185ba8;
    margin: 0px 0px 15px;
}

h1 {
    font-size: 26px;
}
h2 {
    font-size: 20px;
}
h3 {
    font-size: 18px;
}
h4 {
    font-size: 16px;
}

.btn {
    padding: 11px 18.6px 13px 20px;
    border-radius: 10px;
    background-image: linear-gradient(to bottom, #1bb4ed, #18a8e4 14%, #108acc 39%, #035aa6 71%, #004b9a 81%, #004b9a);
    min-width: 120px;
    min-height: 40px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: 0.2s ease all;

    &:hover {
        text-decoration: none;
        background-image: linear-gradient(to bottom, #f2cb15 64%, #f2cb15 20%, #ecbe3e);
        color: #fff;
    }

    &-second {
        background-image: linear-gradient(to right, #f2cb15, #f2cb15 59%, #dcb137);

        &:hover {
            background-image: linear-gradient(to left, #f2cb15, #f2cb15 59%, #dcb137);
        }
    }
}

.swiper-button {
    &-next,
    &-prev {
        width: 21x;
        height: 11px;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }

        &::after {
            display: none;
        }

        svg {
            width: 21px;
            height: auto;
        }
    }

    &-next {
        right: 20px;

        svg {
            transform: rotate(270deg);
        }
    }

    &-prev {
        left: 20px;

        svg {
            transform: rotate(90deg);
        }
    }
}

.swiper-pagination {
    &-bullet {
        width: 12px;
        height: 12px;
        background: $white;
        position: relative;
        opacity: 1;
        margin: 0 5px !important;

        &-active {
            background: $blue;

            &::after {
                border-radius: 50%;
            }
        }
    }
}
