// Compile "main.scss" as "css/main.css"

@import "scss/fonts";

/**
 * Import Bootstrap sources
 * https://getbootstrap.com/docs/5.2/customize/sass
 */

// @import "../.npm/node_modules/bootstrap/scss/bootstrap"; // Include all of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../.npm/node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// Options
$enable-caret: false;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true; 
$enable-grid-classes: true;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: false;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

$spacer: 1rem;

// Theme color
$primary: #185ba8;
$secondary: #f2cb15;
$success: #4BB92F;
$warning: #f2cb15;
$danger: #f91515;
$white: #fff;
$dark: #404040;
$blue: #185ba8;
$bg: #f2f2f2;
$border: #ddd;

$theme-colors: (
	"primary":    $primary,
	"secondary":  $secondary,
	"success":    $success,
	"warning":    $warning,
	"danger":     $danger,
	"dark":       $dark,
	"blue":       $blue,
	"white":      $white,
	"bg":      	  $bg,
	"border":     $border,
);

// Fonts
$font-family-sans-serif: 'Averta Std CY', serif; 
$font-family-base: $font-family-sans-serif;

$font-sizes: (60, 58, 40, 36, 34, 32, 30, 28, 26, 24, 22, 20, 18, 16, 14, 12, 10);

$font-size-base: 1rem;

$line-height-base: 1.35;

// Links
$link-decoration: none;
$link-color: $blue;

// Container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1324px,
);

$grid-gutter-width: 1.5rem;
$gutters: (
  0: 0,
  1: $spacer * .625,
  2: $spacer * 1.25,
  3: $spacer * 1.875,
  4: $spacer * 2.5,
  5: $spacer * 3.125,
);

// Buttons + Forms
$input-btn-focus-width: 0;

// Z-index master list
$zindex-sticky: 10;

// Placeholder
$placeholder-opacity-max: 1;

$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3
);


	// 3. Include remainder of required Bootstrap stylesheets
@import "../.npm/node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../.npm/node_modules/bootstrap/scss/maps";
@import "../.npm/node_modules/bootstrap/scss/mixins";
@import "../.npm/node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed: Uncomment components to reduce the filesize
@import "../.npm/node_modules/bootstrap/scss/utilities";
@import "../.npm/node_modules/bootstrap/scss/reboot";
@import "../.npm/node_modules/bootstrap/scss/type";
@import "../.npm/node_modules/bootstrap/scss/images";
@import "../.npm/node_modules/bootstrap/scss/containers";
@import "../.npm/node_modules/bootstrap/scss/grid";
@import "../.npm/node_modules/bootstrap/scss/tables";
@import "../.npm/node_modules/bootstrap/scss/forms";
@import "../.npm/node_modules/bootstrap/scss/buttons";
@import "../.npm/node_modules/bootstrap/scss/transitions";
@import "../.npm/node_modules/bootstrap/scss/dropdown";
@import "../.npm/node_modules/bootstrap/scss/button-group";
//@import "../.npm/node_modules/bootstrap/scss/nav";
//@import "../.npm/node_modules/bootstrap/scss/navbar";
@import "../.npm/node_modules/bootstrap/scss/card";
@import "../.npm/node_modules/bootstrap/scss/accordion";
@import "../.npm/node_modules/bootstrap/scss/breadcrumb";
@import "../.npm/node_modules/bootstrap/scss/pagination";
@import "../.npm/node_modules/bootstrap/scss/badge";
@import "../.npm/node_modules/bootstrap/scss/alert";
@import "../.npm/node_modules/bootstrap/scss/progress";
@import "../.npm/node_modules/bootstrap/scss/list-group";
@import "../.npm/node_modules/bootstrap/scss/close";
@import "../.npm/node_modules/bootstrap/scss/placeholders";
//@import "../.npm/node_modules/bootstrap/scss/toasts";
@import "../.npm/node_modules/bootstrap/scss/modal";
//@import "../.npm/node_modules/bootstrap/scss/tooltip";
@import "../.npm/node_modules/bootstrap/scss/popover";
//@import "../.npm/node_modules/bootstrap/scss/carousel";
@import "../.npm/node_modules/bootstrap/scss/spinners";
//@import "../.npm/node_modules/bootstrap/scss/offcanvas";
@import "../.npm/node_modules/bootstrap/scss/helpers";

@import "../.npm/node_modules/bootstrap-font-sizes/font-sizes";

@import "../.npm/node_modules/swiper/swiper";
@import "../.npm/node_modules/swiper/modules/navigation/navigation";
@import "../.npm/node_modules/swiper/modules/pagination/pagination";
@import "../.npm/node_modules/swiper/modules/scrollbar/scrollbar";

@import "../.npm/node_modules/lity/dist/lity";

$fa-font-path: "../.npm/node_modules/@fortawesome/fontawesome-free/webfonts";
@import "../.npm/node_modules/@fortawesome/fontawesome-free/css/fontawesome";
@import "../.npm/node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../.npm/node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../.npm/node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../.npm/node_modules/@fortawesome/fontawesome-free/scss/v4-shims";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../.npm/node_modules/bootstrap/scss/utilities/api";



// 8. Custom style
@import "scss/pisee-base";
@import "scss/404";
//@import "scss/wordpress";
//@import "scss/image-ratio";
//@import "scss/pisee-comment";


/**
 * Like bootstrap
 */
.rounded-top-end {
  border-radius: 0 20px 0 0;
}

.rounded-bottom-end {
	border-radius: 0 0 20px 0;
}

/**
 * General
 */
:root {
  --swiper-navigation-size: 34px;
  --swiper-theme-color: #eb1b23;
}

main {
}

body {
	/* If WP-Adminbar is visible */
	color: $dark;
	line-height: $line-height-base;
	position: relative;

	&::before {
		position: absolute;
		width: 100%;
		height: 558px;
		background-image: linear-gradient(to top, #fff 1%, #b0e9fc 99%);
		content: '';
		top: 0;
		left: 0;
	}

	&.show-nav {
		.nav-menu {
			opacity: 1;
			visibility: visible;
			left: 0;
		}

		.main-content {
	    margin-left: 330px;
	    width: calc(100% - 330px);

	    @media screen and (max-width: 1399px) {
	    	width: 100%;
	    	margin-left: 0
	    }

	    @media screen and (min-width: 1400px) {
		    &__info {
					.info {
						&-tab {
							ul {
								li {
									min-width: 310px;

									&:first-of-type {
										a {
											padding-left: 0;
										}
									}

									a {
										padding-left: 20px;
									}
								}
							}
						}

						&-chart {
							margin-right: 20px;
						}

						&-account {
							width: calc(100% - 20px - 310px);

							&__item {
								width: calc(50% - 10px);
								margin-right: 20px;

								&:nth-child(2n) {
									margin-right: 0
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 1400px) {
			.manage-content {
				&__slide {
					width: 54.64%;
				}

				&__account {
					width: calc(45.36% - 20px);
				}
			}
		}
	}

	&.home {
		background: url('../images/bg-home.jpg') no-repeat scroll 0 0 transparent;
		background-size: cover;

		&::before {
			display: none;

			@media screen and (max-width: 767px) { 
				display: block;
				background: #fff;
				opacity: 0.5;	
				height: 100%;
			}
		}
	
		.header {
			padding-top: 30px;
		}
	
		main {
			min-height: calc(100vh - 178px);
			padding-top: 80px;

			@media screen and (max-width: 767px) { 
				padding-top: 40px;	
			}

			.col-login {
				min-width: 441px;

				@media screen and (max-width: 1199px) {
					margin-left: auto;
					margin-right: auto;
				}

				@media screen and (max-width: 991px) {
					min-width: unset;
				}
			}
		}
	}

	main {
		padding-top: 10px;
		position: relative;

		&.header-fix {
			padding-top: 98px;

			@media screen and (max-width: 767px) {
				padding-top: 148px;
			}
		}

		.show-nav {
			position: absolute;
			width: 50px;
			height: 50px;
			left: -12px;
			top: 115px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			border-radius: 50%;
			cursor: pointer;

			@media screen and (max-width: 1399px) {
				position: fixed;
				z-index: 100;
			}	

			&:hover {
				background-image: linear-gradient(to bottom, #f2cb15, #f2cb15 69%, #ecbe3e);

				svg {
					path {
						fill: #fff !important;
					}
				}
			}

			svg {
				width: 11px;
				height: auto;
			}
		}
	}
}

a {
	font-weight: normal;
    color: $dark;
    text-decoration: none;

	&:hover,
	&:focus {
		color: #212631;
    	text-decoration: underline;
		outline: none;
	}
}

img {
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}



.footer {
	padding-top: 30px;
	position: relative;

	&__link {
		margin-bottom: 10px;

		a {
			padding: 0 10px;

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}
		}
	}
}

.header {
	padding-top: 20px;
	padding-bottom: 8px;
	position: relative;

	&__icon {
		display: none;
		align-items: center;
		margin-right: 70px;

		@media screen and (max-width: 991px) {
			margin-right: 20px
		}

		a {
			width: 38px;
			height: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-image: linear-gradient(180deg,#1bb4ed,#035aa6 61%,#004b9a 86%,#004b9a);
			border-radius: 50%;
			margin-left: 20px;

			@media screen and (max-width: 991px) {
				margin-left: 10px;
			}

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	&.fixed {
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 100;
		background: linear-gradient(to bottom,#b0e9fc 0,#fff 558px);

		&.show {

			.header {
				&__icon {
					display: flex;
				}
			}
			.user-info {
				> span,
				> svg {
					display: none
				}

				.icon {
					display: flex;
				}
			}
		}
		
	}

	&__logo {
		@media screen and (max-width: 767px) { 
			width: 100%;
			text-align: center;		
		}
		svg {
			width: 383px;
			height: auto;
		}
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: 767px) { 
			flex-wrap: wrap;		
		}
	}

	&__right {
		display: flex;
		align-items: center;

		@media screen and (max-width: 767px) { 
			width: 100%;	
			justify-content: center;
			margin-top: 20px;	
		}
	}

	&__hotline {
		margin-right: 20px;
		a {
			display: flex;
			align-items: center;
			transition: 0.2s ease all;

			@media screen and (max-width: 991px) { 
				font-size: 0;
			}

			&:hover {
				text-decoration: none;
				transform: scale(1.2);

				svg {
					path {
						fill: #f2cb15 !important;
					}
				}
			}

			svg {
				margin-right: 3px;
			}
		}
	}

	&__download {
		margin-right: 15px;

		a {
			font-size: 12px;
			
			svg {
				margin-right: 2px;
			}
		}
	}

	&__info {
		position: relative;

		.user-info {
			display: flex;
			align-items: center;
			cursor: pointer;

			@media screen and (max-width: 991px) { 
				> span,
				> svg {
					display: none
				}

				.icon {
					display: flex;
				}
			}

			.icon {
				display: none;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				background: #fff;
				border-radius: 50%;

				@media screen and (max-width: 991px) { 
					display: flex;
				}

				&:hover {
					background-image: linear-gradient(180deg,#f2cb15,#f2cb15 69%,#ecbe3e);

					svg {
						path {
							fill: #fff !important;
						}
					}
				}

				svg {
					width: 15px;
					height: auto;
					margin-left: 0;
					transform: rotate(0) !important;
				}
			}

			&.show {
				svg {
					transform: rotate(180deg);
				}

				.user-info {
					&__content {
						display: block;
					}
				}
			}

			svg {
				margin-left: 10px;
				transition: 0.2s ease all;
			}

			span {
				font-weight: bold;
				color: $primary;
				font-size: 14px;
				max-width: 310px;
			}

			&__content {
				width: 400px;
				background: url('../images/bg-user-info.svg') no-repeat;
				background-size: cover;
				position: absolute;
				top: 45px;
				right: -60px;
				padding: 46px 25px 30px;
				z-index: 2;
				display: none;

				&.show {
					display: block;
				}

				.user-header {
					display: flex;
					align-items: center;
					padding-bottom: 19px;
					border-bottom: 1px solid #dfdfdf;
					margin-bottom: 10px;

					svg {
						margin-right: 10px;
						width: 40px;
					}
				}

				.user-detail {
					padding-bottom: 12px;
					border-bottom: 1px solid #dfdfdf;
					margin-bottom: 19px;

					&__item {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 20px;

						&:last-of-type {
							margin-bottom: 0;
						}

						.icon {
							width: 21px;
							margin-right: 8px;
						}
					}
				}

				.user-action {
					display: flex;
					align-items: center;
					justify-content: space-between;

					&__left,
					&__right {
						a {
							display: flex;
							align-items: center;

							svg {
								margin-right: 4px;
							}
						}
					}
				}
			}
		}
	}

	&__notification {
		margin-left: 20px;
		position: relative;

		.notification-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			position: relative;
			border-radius: 50%;
			background: #fff;
			cursor: pointer;

			&:hover {
				background-image: linear-gradient(to bottom, #f2cb15, #f2cb15 69%, #ecbe3e);

				svg {
					path {
						fill: #fff !important;
					}
				}
			}

			span {
				position: absolute;
				width: 16px;
				height: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $danger;
				color: #fff;
				font-size: 8px;
				top: 0;
				right: 0;
				border-radius: 50%;
			}
		}

		.notification-content {
			position: absolute;
			right: -50px;
			top: 70px;
			width: 400px;
			background-image: linear-gradient(to bottom, #4aceff 0, #257fde 50px);
			box-shadow: 0 0 6px 0 rgba(24, 91, 168, 0.27);
			z-index: 2;
			padding: 35px 0 0px;
			border-radius: 15px;
			display: none;

			&.show {
				display: block;
			}

			&::before {
				position: absolute;
				width: 0; 
				height: 0; 
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-bottom: 20px solid #4aceff;
				right: 49px;
    			top: -20px;
				content: '';
			}

			&__soon {
				padding: 15px;
			}

			&__tab {
				ul {
					display: flex;
					justify-content: space-between;
					list-style: none;
					margin: 0;
					padding: 0;

					li {
						list-style: none;

						a {
							text-align: center;
							color: #fff;
							font-size: 12px;
							padding: 10px 15px;
							text-decoration: none;

							&:hover {
								text-decoration: none;
							}

							&.active {
								font-weight: bold;
								color: $primary;
								background: #fff;
								border-radius: 10px 10px 0 0;
							}
						}
					}
				}
			}

			&__content {
				.tabcontents {
					background: #fff;
					padding: 20px;
					display: none;
					border-radius: 0 0 15px 15px;

					&.active {
						display: block;
					}

					.time {
						margin-bottom: 8px;
					}

					.noti {
						overflow: hidden;
						padding: 10px 20px 10px 0;
						border-radius: 10px;
						position: relative;

						&::before {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							content: '';
							background-image: linear-gradient(70deg, #f9fcfe 6%, #b0e9fc 99%);
							opacity: 0.6;
						}

						p {
							position: relative;
							margin-bottom: 10px;
						}

						&.new {
							&::after {
								width: 10px;
								height: 10px;
								position: absolute;
								content: '';
								top: 10px;
								right: 10px;
								background: $danger;
								border-radius: 50%;
							}
						}
					}
				}
			}

			&__item {
				margin-bottom: 15px;
			}
		}
	}

	&__btn,
	&__language {
		a {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $primary;
			border-radius: 50%;
			font-size: 12px;
			font-weight: bold;
			color: $primary;

			&:hover {
				color: #fff;
				border-color: $secondary;
				background: $secondary;
				text-decoration: none;

				svg {
					path {
						fill: #fff !important;
					}
				}
			}
		}
	}

	&__btn {
		margin-right: 15px;
	}
}

.block-login {
	&__sub {
		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	h1 {
		margin-bottom: 20px;
	}

	form {
		.form-item {
			position: relative;
			margin-bottom: 30px;

			&.captcha {
				input {
					padding-left: 20px;
				}

				.captcha-login {
					position: absolute;
					right: 0;
					top: 0;
					padding-right: 40px;
					background: #fff;
					border-radius: 15px;

					svg {
						left: auto;
						right: 10px;
					}
				}
			}

			&.pass {
				span {
					width: 20px;
					height: 18px;
					background: url('../images/eye.svg') no-repeat;

					&.active {
						background: url('../images/eye-crossed.svg') no-repeat;
					}
				}
			}

			svg {
				position: absolute;
				left: 10px;
				top: 18px;
			}

			span {
				position: absolute;
				top: 18px;
				right: 10px;

			}

			input {
				width: 100%;
				background: rgb(255 255 255 / 60%);
				border-radius: 15px;
				padding: 15px 10px 15px 28px;
				border: 1px solid #fff;
				color: $primary;
				font-size: 14px;
				min-height: 50px;
				line-height: 1;
				outline: 0;

				&::-webkit-input-placeholder {
					color: $primary;
				  }
				  
				&:-ms-input-placeholder {
					color: $primary;
				  }
				  
				&::placeholder {
					color: $primary;
				  }
			}
		}

		.form-action {
			margin-bottom: 20px;

			input,
			a {
				width: 100%;
				color: $dark;
				min-height: 50px;
				outline: 0;
			}
		}
	}
}

.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 100;
	top: 0;
	left: 0;

	&::before {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.82;
  		background-color: $dark;
		top: 0;
		left: 0;
		content: '';
		z-index: 1;
	}

	&__wrap {
		position: absolute;
		z-index: 2;
		max-width: 640px;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);

	}

	&__close {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background: #fff;
		top: 20px;
		right: 20px;
		position: absolute;
		z-index: 3;
		border-radius: 50%;
		cursor: pointer;

		svg {
			transform: rotate(45deg);
			cursor: pointer;
		}
	}
}

//home

.nav-menu {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	transition: 0.2s ease all;
	border-radius: 20px;
	background-color: #fff;
	padding: 20px 30px;
	border: 1px solid #dfdfdf;
	width: 310px;
	left: -320px;
	top: 0;
	z-index: 10;

	&-action {
		position: absolute;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 25px;
		right: -15px;
		border: solid 1px #dfdfdf;
  		background-color: #fff;
		cursor: pointer;
		border-radius: 50%;

		&:hover {
			background: linear-gradient(180deg,#f2cb15,#f2cb15 69%,#ecbe3e);

			svg {
				path {
					fill: #fff !important;
				}
			}
		}

		svg {
			transform: rotate(90deg);
		}
	}

	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	> ul {
		> li {
			margin-bottom: 15px;
			&.show-sub {
				&.show {
					.parent {
						.arrow {
							svg {
								transform: rotate(0);
							}
						}
					}
					> ul.sub {
						display: block;
					}
				}
			}

			> a {
				font-weight: bold;
				position: relative;
				display: block;
				line-height: 40px;
				margin-bottom: 12px;
				text-decoration: none;

				&:hover,
				&.active {
					.icon {
						background-image: linear-gradient(to bottom, #f2cb15, #f2cb15 69%, #ecbe3e);
					}
				}

				.arrow {
					position: absolute;
					right: 0;
					top: 15px;
					line-height: 0;

					svg {
						transform: rotate(270deg);
					}
				}

				.icon {
					position: absolute;
					left: -50px;
					top: 0;
					display: flex;
					border-radius: 20px;
					border: solid 1px #dfdfdf;
					background-color: #fff;
					width: 40px;
					height: 40px;
					align-items: center;
					justify-content: center;
				}
			}

			> ul {
				margin-left: 10px;
				display: none;
				> li {
					> a {
						line-height: 20px;
						padding: 10px 0;
						position: relative;
						display: flex;
						align-items: center;
						font-size: 12px;
						text-decoration: none;

						span {
							display: flex;
							align-items: center;
							position: relative;
							z-index: 2
						}

						&:hover,
						&.active {
							color: $primary;
							font-weight: bold;

							&::before {
								position: absolute;
								left: -40px;
								top: 0;
								width: 5px;
								height: 100%;
								content: '';
								border-radius: 5px;
  							background-color: #b88d2d;
  							z-index: 2;
							}

							&::after {
								position: absolute;
								top: 0;
								left: -40px;
								width: 300px;
								height: 100%;
								content: '';
								border-radius: 0 5px 5px 0;
  							background-image: linear-gradient(to left, #f2cb15 64%, #f2cb15 20%, #ecbe3e);
  							z-index: 1;
							}

							svg {
								path {
									fill: $primary !important;
								}
							}
						}

						svg {
							width: 20px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}

@keyframes my { 
	 0% { color: $danger;  } 
	 50% { color: #fff;  }
	 100% { color: $danger;  } 
 } 

.main-content {
	width: 100%;
	transition: 0.2 ease all;

	&__status {
		display: flex;
		align-items: center;
		width: 100%;
		border-radius: 10px;
  		background-color: rgb(255 255 255 / 70%);
		padding: 10px 212px 10px 20px;
		min-height: 50px;
		position: relative;
		margin-top: 15px;

		.status {
			&-news {
				padding-right: 10px;
			 	animation: my 1s infinite;
			}

			&-content {
				padding-left: 10px;
				position: relative;

				&::before {
					position: absolute;
					width: 2px;
					height: 30px;
					left: -1px;
					top: 50%;
					background: $primary;
					content: '';
					transform: translateY(-50%)
				}
			}

			&-close {
				align-items: center;
				background: #fff;
				border-radius: 50%;
				cursor: pointer;
				display: flex;
				height: 30px;
				justify-content: center;
				position: absolute;
				right: 20px;
				top: 10px;
				width: 30px;

				svg {
					transform: rotate(45deg);
				}
			}

			&-btn {
				min-height: 30px;
				padding: 3px 15px;
				position: absolute;
    			right: 70px;
			}
		}
	}

	&__function {
		margin-top: 33px;
		margin-bottom: 29px;
		transition: 0.2 ease all;

		&.fix {
			opacity: 0;
		}

		.function {
			&-header {
				position: relative;
				margin-bottom: 20px;
			}

			&-action {
				position: absolute;
				right: 0;
				top: 0;

				a {
					display: flex;
					align-items: center;
					text-decoration: underline;

					svg {
						margin-right: 11px;
					}
				}
			}

			&-content {
				display: flex;
				justify-content: space-between;
				width: 100%;

				@media screen and (max-width: 575px) {
					flex-wrap: wrap;
					justify-content: center;
				}
			}

			&-item {
				width: calc((100% - (25px * 4)) / 5);
				cursor: pointer;

				@media screen and (max-width: 575px) {
					width: calc((100% - (20px * 2)) / 3);

				}
				
				&:hover {
					.function-icon {
						background-image: linear-gradient(to bottom, #f2cb15, #f2cb15 59%, #dcb137 71%, #dcb137);
					}
				}
			}

			&-icon {
				display: flex;
				width: 80px;
				height: 80px;
				justify-content: center;
				align-items: center;
				//background: url('../images/function-icon.png');
				background-image: linear-gradient(to bottom, #1bb4ed, #035aa6 61%, #004b9a 86%, #004b9a);
				border-radius: 50%;
				margin: 0 auto 10px;
			}

			&-name {
				font-weight: bold;
				color: $primary;
				text-align: center;
				font-size: 14px;
			}
		}
	}

	&__info {
		margin-bottom: 20px;
		
		.info {
			&-header {
				margin-bottom: 20px;
			}

			&-tab {
				margin-bottom: 20px;

				ul {
					list-style: none;
					margin: 0;
					padding: 0;
					display: flex;

					li {
						list-style: none;
						min-width: 380px;

						@media screen and (min-width: 1400px) {
							&:nth-child(2) {
								min-width: 500px;
							}
						}

						@media screen and (max-width: 1199px) {
							min-width: auto;
							width: calc(100% / 3);
						}

						&:first-of-type {
							a {
								padding-left: 0;
							}
						}

						a {
							font-size: 14px;
							font-weight: bold;
							position: relative;
							padding-bottom: 5px;
							display: block;
							text-decoration: none;
							padding-left: 10px;

							&.active {
								&::after {
									height: 2px;
									background: $primary;
								}
							}

							&:hover {
								color: $primary;
								text-decoration: none;
							}

							&::after {
								width: 100%;
								height: 1px;
								position: absolute;
								bottom: 0;
								left: 0;
								background: #dfdfdf;
								content: '';
							}
						}
					}
				}
			}

			&-chart {
				width: 310px;
				position: relative;
				margin-right: 80px;

				@media screen and (max-width: 1199px) {
					margin-right: 20px
				}

				@media screen and (max-width: 991px) {
					margin: 0 auto 30px;
				}

				&__content {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}

			&-eye {
				text-align: center;
			}

			&-account {
				width: calc(100% - 80px - 310px);

				@media screen and (max-width: 1199px) {
					width: calc(100% - 20px - 310px);
				}

				@media screen and (max-width: 991px) {
					width: 100%;
				}

				&__header {
					position: relative;
					margin-bottom: 25px;
					margin-top: 21px;
				}

				&__detail {
					position: absolute;
					top: 0;
					right: 0;
				}

				&__content {
					display: flex;
					flex-wrap: wrap;
				}

				&__item {
					width: calc(50% - 40px);
					margin-right: 80px;
					margin-bottom: 30px;
					border-radius: 15px;
  				border: solid 1px #dfdfdf;
  				display: flex;

  				@media screen and (max-width: 1199px) {
  					width: calc(50% - 10px);
  					margin-right: 20px;
  				}

  				@media screen and (max-width: 575px) {
  					width: 100%;
  					margin-right: 0 !important;
  				}

					&:nth-child(2n) {
						margin-right: 0;
					}

					&:nth-child(1) {
						.info-account {
							&__ratio {
								background-image: linear-gradient(to bottom, #4aceff, #257fde 107%);
							}
						}
					}
					&:nth-child(2) {
						.info-account {
							&__ratio {
								background-image: linear-gradient(to bottom, #1bb4ed, #004b9a 113%);
							}
						}
					}
					&:nth-child(3) {
						.info-account {
							&__ratio {
								background-image: linear-gradient(to bottom, #f2cb15, #f2cb15 69%, #ecbe3e);

							}
						}
					}
					&:nth-child(4) {
						.info-account {
							&__ratio {
								background-image: linear-gradient(to bottom, #e8e8e8, #a7a7a7 122%);
							}
						}
					}
				}

				&__ratio {
					width: 70px;
					height: 70px;
					border-radius: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 20px;
				  font-weight: bold;
				  color: #fff;
				}

				&__info {
					width: calc(100% - 70px);
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 5px 13px;
				}
			}
		}
	}

	&__manage {
		margin-bottom: 24px;

		.manage-content {
			display: flex;
			flex-wrap: wrap;

			&__item {
				padding-bottom: 8px;
				margin-bottom: 10px;
				border-bottom: 1px dashed rgb(144 144 144 / 20%);
				display: flex;
				align-items: center;
				position: relative;

				svg {
					position: absolute;
					right: 0;
					top: 5px;
				}

				&:last-of-type {
					border-bottom: none;
				}

				.logo {
					width: 30px;
					margin-right: 10px;
				}

				.info {
					width: calc(100% - 40px);
					line-height: 1;
				}
			}

			&__header {
				position: relative;
				margin-bottom: 30px;
			}

			&__add {
				position: absolute;
				right: 0;
				top: 0;

				a {
					text-decoration: underline;
					align-items: center;
    			display: flex;

					svg {
						margin-right: 11px;
					}
				}
			}

			&__slide {
				width: 49.23%;
				margin-right: 20px;

				@media screen and (max-width: 991px) {
					width: 100%;
				}

				.swiper {
					&-slide {
						.slider__img {
							position: relative;
							height: 366px;
							overflow: hidden;
							border-radius: 20px;
							width: 100%;

							img {
								position: absolute;
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}
			}

			&__account {
				width: calc(100% - 49.23% - 20px);
				border-radius: 20px;
  			background-image: linear-gradient(53deg, rgba(249, 252, 254, 0) 46%, rgba(176, 233, 252, 0.6));
  			padding: 29px 20px 29px 0;

  			@media screen and (max-width: 991px) {
  				width: 100%;
  			}
			}
		}
	}

	&__history {
		border-radius: 20px;
  	background-image: linear-gradient(30deg, rgba(249, 252, 254, 0) 45%, rgba(176, 233, 252, 0.6) 79%);
  	padding: 30px 20px 30px 0;
  	margin-bottom: 40px;

  	@media screen and (max-width: 991px) {
  		.history-content {
  			overflow-x: scroll;
  		}
  	}

  	h2 {
  		margin-bottom: 20px;
  	}

  	.table {

  		@media screen and (max-width: 991px) {
  			width: 916px;
  		}

  		&-item {
  			width: 100%;
  			display: flex;
  			justify-content: space-between;
  			align-items: center;
  			background: #fff;
  			border-radius: 15px;
  			margin-bottom: 15px;
  			cursor: pointer;

  			&:hover {
  				background: #FFFCEC 0% 0% no-repeat padding-box;
					box-shadow: 0px 0px 6px #00000012;
  			}

  			&:last-of-type {
  				margin-bottom: 0;
  			}

  			&.first {
  				background: none;
  				margin-bottom: 18px;

  				> div {
	  				font-size: 14px;
	  				font-weight: bold;
	  				padding: 0 20px;

	  				&:nth-child(1),
	  				&:nth-child(5) {
  						padding: 0px 15px;
  					}
  				}
  			}

  			> div {
  				padding: 12px 20px;
  				font-size: 12px;
  				width: 100%;
  				display: flex;
  				align-items: center;

  				&:nth-child(1) {
  					max-width: 175px;
  					padding: 12px 15px;

  					@media screen and (max-width: 991px) {
  						width: 175px;
  					}
  				}

  				&:nth-child(2) {
  					max-width: 195px;

  					@media screen and (max-width: 991px) {
  						width: 195px;
  					}
  				}

  				&:nth-child(3) {
  					max-width: 195px;

  					@media screen and (max-width: 991px) {
  						width: 195px;
  					}
  				}

  				&:nth-child(4) {
  					max-width: 143px;

  					@media screen and (max-width: 991px) {
  						width: 143px;
  					}
  				}

  				&:nth-child(5) {
  					max-width: 110px;
  					padding: 12px 15px;

  					@media screen and (max-width: 991px) {
  						width: 110px;
  					}
  				}

  				&:nth-child(6) {
  					max-width: 130px;

  					@media screen and (max-width: 991px) {
  						width: 130px;
  					}

  					span {
  						display: flex;
  						align-items: center;
  						justify-content: center;
  						width: 90px;
  						height: 30px;
  						font-size: 12px;
						  font-weight: normal;
						  color: #fff;
						  text-transform: uppercase;
						  border-radius: 8px;

						  &.complete {
						  	background-image: linear-gradient(to bottom, #1bb4ed, #18a8e4 14%, #108acc 39%, #035aa6 71%, #004b9a 81%, #004b9a);
						  }

						  &.wait {
						  	background-image: linear-gradient(to bottom, #f2cb15, #f2cb15 59%, #dcb137 71%, #dcb137);
						  }

						  &.fail {
						  	background-image: linear-gradient(to bottom, #e8e8e8, #a7a7a7);
						  }
  					}
  				}

  				&.price {
  					font-weight: bold;
  					color: $primary;
  				}
  			}
  		}
  	}
	}
}