//@import url("//fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,600;6..12,700&family=Nunito:wght@400;500;600;700&display=swap");

@font-face {
    font-family: 'Averta Std CY';
    src: url('../fonts/AvertaStdCY-BoldItalic.eot');
    src: url('../fonts/AvertaStdCY-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvertaStdCY-BoldItalic.woff2') format('woff2'),
        url('../fonts/AvertaStdCY-BoldItalic.woff') format('woff'),
        url('../fonts/AvertaStdCY-BoldItalic.ttf') format('truetype'),
        url('../fonts/AvertaStdCY-BoldItalic.svg#AvertaStdCY-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta Std CY';
    src: url('../fonts/AvertaStdCY-RegularItalic.eot');
    src: url('../fonts/AvertaStdCY-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvertaStdCY-RegularItalic.woff2') format('woff2'),
        url('../fonts/AvertaStdCY-RegularItalic.woff') format('woff'),
        url('../fonts/AvertaStdCY-RegularItalic.ttf') format('truetype'),
        url('../fonts/AvertaStdCY-RegularItalic.svg#AvertaStdCY-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta Std CY';
    src: url('../fonts/AvertaStdCY-Regular.eot');
    src: url('../fonts/AvertaStdCY-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvertaStdCY-Regular.woff2') format('woff2'),
        url('../fonts/AvertaStdCY-Regular.woff') format('woff'),
        url('../fonts/AvertaStdCY-Regular.ttf') format('truetype'),
        url('../fonts/AvertaStdCY-Regular.svg#AvertaStdCY-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta Std CY';
    src: url('../fonts/AvertaStdCY-Bold.eot');
    src: url('../fonts/AvertaStdCY-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvertaStdCY-Bold.woff2') format('woff2'),
        url('../fonts/AvertaStdCY-Bold.woff') format('woff'),
        url('../fonts/AvertaStdCY-Bold.ttf') format('truetype'),
        url('../fonts/AvertaStdCY-Bold.svg#AvertaStdCY-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

